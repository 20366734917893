<template lang="pug">
  .jumbotron(:class="{'jumbotron--disabled': disabled, 'jumbotron--has-title': title, 'jumbotron--small': small}")
    h3.jumbotron-title(v-if="title") {{ title }}
    .jumbotron_container(:class="{'jumbotron--additional': hasAdditionalSlot}")
      .jumbotron__list
        slot(name="content")
      slot(name="additional-content")
</template>

<script>
export default {
  name: 'Jumbotron',

  props: {
    title: {type: String, default: 'Filters'},
    disabled: {type: Boolean, default: false},
    small: { type: Boolean, default: false }
  },

  computed: {
    hasAdditionalSlot() {
      return this.$slots['additional-content']
    }
  }
}
</script>

<style lang="scss">
.jumbotron {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(#aeaeae, 0.3);
  border-left: none;
  border-right: none;
  background-color: $white;
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.5s ease-in-out;

  @include media("<=tablet") {
    padding: 10px;
  }

  &_container {
    display: flex;
  }

  &-title {
    font-family: "Archivo N", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: #272c32;
    text-transform: uppercase;
  }

  &__ {
    &list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;

      @include media("<=phone") {
        justify-content: space-around;
      }
    }
  }

  &-- {
    &additional {
      align-items: center;
      justify-content: space-between;

      @include media("<=phone") {
        flex-direction: column;
      }
    }

    &has-title {
      .jumbotron__list {
        margin-top: 10px;
      }
    }

    &disabled {
      opacity: 0.35;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &small {
      padding: 10px 20px;
    }
  }
}
</style>
