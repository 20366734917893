<template lang="pug">
  jumbotron
    template(v-slot:content)
      appDatepicker(
        :value="$route.query[JUMBOTRON_FILTERS.RELEASE_DATE_AFTER]"
        label="Release From"
        @input="setFilter(JUMBOTRON_FILTERS.RELEASE_DATE_AFTER, $event)"
        @change="setFilter(JUMBOTRON_FILTERS.RELEASE_DATE_AFTER, $event)"
        @clear="resetFilter(JUMBOTRON_FILTERS.RELEASE_DATE_AFTER, $event)"
        no-title
        calendar
      )

      appDatepicker(
        :value="$route.query[JUMBOTRON_FILTERS.RELEASE_DATE_BEFORE]"
        label="Release To"
        @input="setFilter(JUMBOTRON_FILTERS.RELEASE_DATE_BEFORE, $event)"
        @change="setFilter(JUMBOTRON_FILTERS.RELEASE_DATE_BEFORE, $event)"
        @clear="resetFilter(JUMBOTRON_FILTERS.RELEASE_DATE_BEFORE, $event)"
        no-title
        calendar
      )

      appDatepicker(
        :value="$route.query[JUMBOTRON_FILTERS.SLOTS_AFTER]"
        label="Slots From"
        @input="setFilter(JUMBOTRON_FILTERS.SLOTS_AFTER, $event)"
        @change="setFilter(JUMBOTRON_FILTERS.SLOTS_AFTER, $event)"
        @clear="resetFilter(JUMBOTRON_FILTERS.SLOTS_AFTER, $event)"
        no-title
        calendar
      )

      appDatepicker(
        :value="$route.query[JUMBOTRON_FILTERS.SLOTS_BEFORE]"
        label="Slots To"
        @input="setFilter(JUMBOTRON_FILTERS.SLOTS_BEFORE, $event)"
        @change="setFilter(JUMBOTRON_FILTERS.SLOTS_BEFORE, $event)"
        @clear="resetFilter(JUMBOTRON_FILTERS.SLOTS_BEFORE, $event)"
        no-title
        calendar
      )

      time-combobox(
        :options="CBR_SLOTS_TIME"
        :value="$route.query[JUMBOTRON_FILTERS.SLOTS_TIME_AFTER]"
        placeholder="Slots time from"
        @input="setFilter(JUMBOTRON_FILTERS.SLOTS_TIME_AFTER, $event)"
      )

      time-combobox(
        :options="CBR_SLOTS_TIME"
        :value="$route.query[JUMBOTRON_FILTERS.SLOTS_TIME_BEFORE]"
        placeholder="Slots time to"
        @input="setFilter(JUMBOTRON_FILTERS.SLOTS_TIME_BEFORE, $event)"
      )

      app-select(
        :options="crmExamLocations"
        :value="$route.query[JUMBOTRON_FILTERS.EXAM_LOCATION]"
        @input="setFilter(JUMBOTRON_FILTERS.EXAM_LOCATION, $event)"
        placeholder="Exam location"
        searchable
        show-close-btn
        filter
      )

      input-filter-widget(
        :value="$route.query[JUMBOTRON_FILTERS.TOTAL_DIFFERENCE]"
        placeholder="Total exam slots"
        @input="setFilter(JUMBOTRON_FILTERS.TOTAL_DIFFERENCE, $event)"
      )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { JUMBOTRON_FILTERS } from '../core/cbr-conts'
import { CBR_SLOTS_TIME } from '@/util/const'
import appSelect from '@/components/global/actions/BaseSelect.vue'
import appDatepicker from '@/components/global/fields/appDatepicker.vue'
import timeCombobox from '@/components/widget/TimeCombobox.vue'
import inputFilterWidget from '@/components/widget/InputFilterWidget.vue'
import jumbotron from '@/components/global/fields/Jumbotron.vue'

export default {
  name: 'InputFilterWidget',

  inject: ['setQuery'],

  props: {
    loading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false}
  },

  computed: {
    ...mapGetters({
      crmExamLocations: 'crmExamLocation/items'
    }),

    isDisabled() {
      return this.loading || this.disabled
    }
  },

  data: () => ({
    JUMBOTRON_FILTERS,
    CBR_SLOTS_TIME
  }),

  mounted() {
    this.getCrmExamLocations({cache: true})
  },

  methods: {
    ...mapActions({
      getCrmExamLocations: 'crmExamLocation/list'
    }),

    async setFilter(type, payload, load = true) {
      if (this.$route.query[type] == payload) return
      if (!payload) return await this.setQuery({[type]: undefined}, load)
      await this.setQuery({[type]: payload}, load);
    },

    resetFilter(type) {
      if (!this.$route.query[type]) return
      this.setFilter([type], undefined)
    }
  },

  components: {
    appSelect,
    appDatepicker,
    timeCombobox,
    inputFilterWidget,
    jumbotron
  }
}
</script>
