<template lang="pug">
  .input-widget
    .input-widget__field(:class="{'time-combobox--error': hasErrors}")
      v-icon(size="15" :color="value ? '#272c32' : 'inherit'") mdi-counter
      validation-provider(ref="provider" rules="between:1,500" v-slot="{ errors }")
        input(
          v-model="number"
          :placeholder="placeholder"
          v-on:keyup.enter="input($event.target.value)"
        )
    img(src="@/assets/img/ui/close.svg" @click="reset" :class="['close-icon', {'active': value}]")
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate';
import { between } from 'vee-validate/dist/rules'
import showingMixin from '@/mixins/showing.mixin';
import { ERRORS_MESSAGES } from "@/util/const";

extend('between', { ...between, message: ERRORS_MESSAGES.ERROR_FIELD })

export default {
  name: 'InputFilterWidget',

  mixins: [showingMixin],

  props: {
    value: {
      required: false
    },
    placeholder: String
  },

  data: () => ({
    number: null
  }),

  computed: {
    hasErrors() {
      return !!(this.number && this.$refs.provider?.errors[0]);
    }
  },

  methods: {
    input(value) {
      if (!this.hasErrors) this.$emit('input', value)
    },

    reset() {
      this.number = null
      this.$emit('input', null)
    }
  },

  mounted() {
    if (this.value) this.number = this.value
  },

  components: {
    ValidationProvider
  }
}
</script>

<style lang="scss">
.input-widget {
  position: relative;
  margin-bottom: 6px;
  margin-right: 15px;

  @include media("<=tablet") {
    margin: 0 5px 5px 0;
  }

  &__field {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    min-width: 140px;
    max-width: 140px;
    border-radius: 4px;
    background-color: #f5f7f9;
    cursor: pointer;
    padding: 0 10px;

    input {
      display: flex;
      width: 100%;
      outline: none;
      color: $title-second;
      font-weight: 500;
      font-size: 12px;
      padding: 0 5px;

      &::placeholder {
        color: #758289;
        font-size: 12px;
      }
    }
  }

  &--error {
    border: 1px solid $error-color;
  }

  .close-icon {
    display: none;
    width: 26px;
    height: 26px;
    position: absolute;
    top: -10px;
    left: auto;
    right: -10px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    @include media("<=tablet") {
      right: -5px;
    }

    &.active {
      display: block;
      transition: opacity 0.3s ease-in-out;
    }
  }
}
</style>
